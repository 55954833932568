import { OfferNotificationTypesEnum } from "./../../../shared/interfaces/IOfferNotificationMessage";
import { OfferService } from "./../../../user/offers/offer.service";
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChildren,
  QueryList,
  Input,
  ViewChild,
} from "@angular/core";
import { ItemsService } from "../../../shared/services/items.service";
import { TopService } from "../../../shared/services/top.service";
import { PubSubService } from "../../../core/pubsub.service";
import { SharedSource } from "../../../core/shared-source";
import { environment } from "../../../../environments/environment";
import { IItem } from "../../interfaces/IItem";
import { IBuyerErrorModal } from "../../interfaces/IBuyerErrorModal";
import { SharedService } from "../../../shared/shared.service";
import { Subscription } from "rxjs";
import { ItemFilterService } from "../../../shared/item-filter/item-filter.service";
import { ListingViewItemComponent } from "../../../shared/listing-view-item/listing-view-item.component";
import { ListingViewItemMobileComponent } from "../../../shared/listing-view-item/listing-view-item-mobile/listing-view-item-mobile.component";
import { GridViewItemComponent } from "../grid-view-item/grid-view-item.component";
import { OutletsService } from "../../outlets/outlets.service";
import { sortTypesEnum } from "../../../shared/enums/sort-types";
import { UserService } from "../../../user/user.service";
import { SessionService } from "../../../services/session.service";
import { Router } from "@angular/router";
import { VirtualScrollerComponent, VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { UserFiltersModalComponent } from "../../user-filters/user-filters-modal/user-filters-modal.component";
import { WaitlistTooltipComponent } from "../../../shared/waitlist-tooltip/waitlist-tooltip.component";
import { RestrictionNoticeComponent } from "../../../shared/ui-components/restriction-notice/restriction-notice.component";
import { NotificationTooltipComponent } from "../../notifications/notification-tooltip/notification-tooltip.component";
import { GradingGuide2Component } from "../../../shared/grading-guide2/grading-guide2.component";
import { ItemDetailDesktopComponent } from "../../../shared/item-detail-desktop/item-detail-desktop.component";
import { ErrorModalComponent } from "../../../shared/ui-components/error-modal/error-modal.component";
import { NgClickOutsideDirective } from "ng-click-outside2";
import { MakeOfferComponent } from "../../../shared/item-detail-desktop/make-offer/make-offer.component";
import { ItemsPreloaderMobileComponent } from "../../../shared/ui-components/items-preloader/items-preloader-mobile/items-preloader-mobile.component";
import { ItemsBreadcrumbsMobileComponent } from "../items-breadcrumbs/items-breadcrumbs-mobile/items-breadcrumbs-mobile.component";
import { NothingFoundBuyerComponent } from "../../nothing-found-buyer/nothing-found-buyer.component";
import { ItemsPreloaderComponent } from "../../../shared/ui-components/items-preloader/items-preloader.component";
import { ItemFilterComponent } from "../../../shared/item-filter/item-filter.component";
import { CreditCardNotificationComponent } from "../../credit-cards/credit-card-notification/credit-card-notification.component";
import { NgIf, NgFor } from "@angular/common";

@Component({
    selector: "app-items-list",
    templateUrl: "./items-list.component.html",
    styleUrls: ["./items-list.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        CreditCardNotificationComponent,
        ItemFilterComponent,
        VirtualScrollerModule,
        NgFor,
        GridViewItemComponent,
        ListingViewItemComponent,
        ItemsPreloaderComponent,
        NothingFoundBuyerComponent,
        ItemsBreadcrumbsMobileComponent,
        ItemsPreloaderMobileComponent,
        ListingViewItemMobileComponent,
        MakeOfferComponent,
        NgClickOutsideDirective,
        ErrorModalComponent,
        ItemDetailDesktopComponent,
        GradingGuide2Component,
        NotificationTooltipComponent,
        RestrictionNoticeComponent,
        WaitlistTooltipComponent,
        UserFiltersModalComponent,
    ],
})
export class ItemsListComponent implements OnInit, OnDestroy {
  @ViewChild(VirtualScrollerComponent) private virtualScroll: VirtualScrollerComponent;
  @ViewChildren(ListingViewItemComponent)
  listItemComponents: QueryList<ListingViewItemComponent>;
  @ViewChildren(GridViewItemComponent)
  gridItemComponents: QueryList<GridViewItemComponent>;
  @ViewChildren(ListingViewItemMobileComponent)
  listItemMobileComponents: QueryList<ListingViewItemMobileComponent>;
  @Input() outletWrapper: HTMLElement;
  paginationMaxSize = 5;
  selectedItem: IItem;
  selectedItemOffer: IItem;
  showDetailedPopUp = false;
  isshowError = false;
  itemListErrorModal: IBuyerErrorModal = {
    bodyText: "",
    title: "",
    isShown: false,
  };

  nothingFoundTitle = "Nothing Found!";
  nothingFoundMessage = "Try changing the search query";
  nothingFoundButtonText = "Refresh";
  isShowGradingGuide = false;
  subscription: Subscription;
  showNotificationTooltip = false;
  notificationItem: IItem = {};
  tooltipPosition: Event;
  showWaitlistTooltip = false;
  waitlistItem: IItem = {};
  waitlistTooltipPosition: Event;
  scrollAnimation: boolean = true;

  noCreditCards = false;
  showCCardBanner: boolean;
  showRestrictedCountryPopup = false;
  isGuest: boolean;

  constructor(
    public outletsService: OutletsService,
    public itemsService: ItemsService,
    public itemFilterService: ItemFilterService,
    public topService: TopService,
    public sharedService: SharedService,
    private _router: Router,
    public userService: UserService,
    public pubSubService: PubSubService,
    public sessionService: SessionService,
    public offerService: OfferService
  ) {}

  ngOnInit() {
    this.isGuest = this.sessionService.userRole === "GUEST" ? true : false;
    this.itemsService.getConditionMapping();
    this.sharedService.isLogoutPage = false;
    this.sharedService.showPreCart = true;
    this.topService.isCatalog = true;
    this.itemsService.isLandingPage = false;
    // for mobile
    this.topService.showFilterBtn = true;

    this.subscription = this.pubSubService.sharedSubject.subscribe(
      (mySharedValues) => {
        if (
          mySharedValues.name === SharedSource.itemsListChanged ||
          mySharedValues.name === SharedSource.itemsScrollingStarted
        ) {
          this.scrollToBottom();
        }
        if (mySharedValues.name === SharedSource.itemsListChanged) {
          this.afterResize();
        }
        if (mySharedValues.name === SharedSource.itemRestrictedForCountry) {
          this.toggleRestrictedCountryPopup(true);
          this.selectedItem = mySharedValues.data;
        }
        if (
          mySharedValues.name === SharedSource.refreshItemList ||
          mySharedValues.name === SharedSource.changeCustomer ||
          mySharedValues.name === SharedSource.offerFeatureSwitched
        ) {
          this.refreshItemList(mySharedValues.data);
        }
        if (mySharedValues.name === SharedSource.offerItemChanged) {
          this.selectedItem = mySharedValues.data;
        }
      }
    );
  }

  toggleRestrictedCountryPopup(show: boolean) {
    this.showRestrictedCountryPopup = show;
  }

  scrollToBottom(): void {
    if (
      this.outletWrapper &&
      !this.topService.isMobile &&
      this.scrollAnimation
    ) {
      setTimeout(() => {
        this.virtualScroll.scrollToPosition(-232, 600);this.scrollAnimation = false;
      }, 50);
    }
  }

  afterResize(): void {
    if (this.virtualScroll) {
      this.virtualScroll.refresh();
    }
  }

  onScrollDown(event) {
    const endPerPage =
      this.itemsService.paginationInfo.currentPage *
      this.itemsService.paginationInfo.pageSize;
    if (event.endIndex < endPerPage - 1) {
      return;
    }

    this.itemsService.paginationInfo.currentPage++;
    this.itemsService.getItems();
  }

  refresh(): void {
    this.topService.searchString = "";
    this._router.navigate([`${this.userService.getUrlByRole()}/home`]);
  }

  openitemDetailedMode(item: IItem): void {
    this.selectedItem = item;
    this.showDetailedPopUp = true;
    this.sharedService.isMobileSortingPopup = true;
  }

  hideDetailedPopUp(): void {
    this.showDetailedPopUp = false;
    this.sharedService.isMobileSortingPopup = false;
    this.selectedItem = { id: "" };
  }

  showError(errorModal: IBuyerErrorModal): void {
    this.itemListErrorModal = errorModal;
  }

  openGradingGuide(item: IItem): void {
    this.selectedItem = item;
    this.sharedService.gradingGuideHtmlPath =
      environment.imageBaseUrl + item.gradingGuideUrl + "index6.html";
    this.sharedService.isGradingGuideOpen = true;
  }

  alertConfirmBtn(): void {
    event.preventDefault();
    event.stopPropagation();
    this.sharedService.isGradingGuideOpen = false;
    this.isShowGradingGuide = false;
    if (!this.showDetailedPopUp) {
      this.selectedItem = { id: "" };
    }
  }

  hideError(): void {
    this.itemListErrorModal.isShown = false;
  }

  addToCart(event: Event) {
    this.itemsService.closeExwPopup(event);

    // gridView is the oposite of expected,
    // if true the listingViewComponent is the one showing
    if (!this.topService.gridView && !this.topService.isMobile) {
      const gridItemCmp = this.gridItemComponents.find((gItem) => {
        return (
          this.itemsService.selectedItemExwPopup &&
          gItem.item.id === this.itemsService.selectedItemExwPopup.id
        );
      });

      if (gridItemCmp) {
        gridItemCmp.addToCart(true);
      }
    } else if (this.topService.gridView && !this.topService.isMobile) {
      const listingItemCmp = this.listItemComponents.find((lItem) => {
        return (
          this.itemsService.selectedItemExwPopup &&
          lItem.item.id === this.itemsService.selectedItemExwPopup.id
        );
      });

      if (listingItemCmp) {
        listingItemCmp.addToCart(true);
      }
    } else {
      const listingItemMobileCmp = this.listItemMobileComponents.find(
        (lItem) => {
          return (
            this.itemsService.selectedItemExwPopup &&
            lItem.item.id === this.itemsService.selectedItemExwPopup.id
          );
        }
      );

      if (listingItemMobileCmp) {
        listingItemMobileCmp.addToCart(true);
      }
    }
  }

  scrollUpInOutlets(event) {
    if (event.scrollStartPosition > 0 && event.start === 0) {
      this.pubSubService.sharedSubject.next({
        name: SharedSource.itemsScrollingStarted,
        data: true,
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.sharedService.showPreCart = false;
    this.itemsService.selectedItemExwPopup = undefined;
    if (!this.outletsService.isCalledFromMenu) {
      this.itemsService.clearAllFiltersAndNav();
    }
    this.itemsService.exwPoints = undefined;

    // Reseting sort to default
    this.itemsService.currentSort = sortTypesEnum.none;

    this.topService.showFilterBtn = false;
    this.selectedItem = undefined;
    this.itemsService.skipEXW = false;
  }

  showOfferpopup(item: IItem): void {
    if (!this.isGuest) {
      this.selectedItemOffer = item;
    }
  }

  hideOfferpopup(): void {
    this.selectedItemOffer = undefined;
  }

  showNotification(tooltip): void {
    this.showNotificationTooltip = false;
    setTimeout(() => {
      this.notificationItem = tooltip.item;
      this.tooltipPosition = tooltip.event;
      this.showNotificationTooltip = true;
    }, 100);
  }

  hideNotificationTooltip(): void {
    this.showNotificationTooltip = false;
  }

  showWaitlist(tooltip): void {
    if (tooltip.isShowWaitlist) {
      this.waitlistItem = tooltip.item;
      this.waitlistTooltipPosition = tooltip.event;
      this.showWaitlistTooltip = true;
    } else {
      this.showWaitlistTooltip = false;
    }
  }

  hideWaitlistTooltip(): void {
    this.showWaitlistTooltip = false;
  }

  public itemTrackById(index: number, item: IItem): string {
    if (!item) return null;
    return item.id;
  }

  isBuyerPortal(): boolean {
    return ["BUYER"].includes(sessionStorage.getItem("user_role"));
  }

  isCreditCardBanner(): boolean {
    return (
      this.isBuyerPortal() &&
      this.userService.isCreditCardAllowed &&
      this.userService.isCreditCardListEmpty === 0 &&
      this.sessionService.closeCreditCardBanner === "false"
    );
  }

  closeCCardNotification() {
    this.sessionService.closeCreditCardBanner = "true";
  }

  closeRestrictedCountryPopup() {
    this.showRestrictedCountryPopup = false;

    if (!this.showDetailedPopUp) {
      this.selectedItem = { id: "" };
    }
  }

  refreshItemList(notiificationType?: OfferNotificationTypesEnum) {
    if (notiificationType == OfferNotificationTypesEnum.offerFeatureSwitched) {
      this.hideDetailedPopUp();
    }
    this.itemsService.getItems();
  }
}
